import {isNullUndefined, isEmptyArray, isString, isArray, isObject} from "../../util/"

export const getContentAsObject = query => {
    let items = {};

    for(let contentfulType of Object.keys(query)){
        for(let edge of query[contentfulType].edges){
          let arrayCopy = [];
          if(items[contentfulType]){
              arrayCopy.push(...items[contentfulType], edge)
              items[contentfulType] = arrayCopy;
          }else{
            arrayCopy.push(edge);
            items[contentfulType] = arrayCopy;
          }
        }
    }

    return items;
}

export const getFilteredContentFromSearch = (content, searchTerm) => {
    if(isNullUndefined(searchTerm)){
        return content;
    }

    let filteredNodes = [];

    const doesNodeExistOnFilteredNodes = (nodes, node) => {
      const nodeExists = nodes.filter(nodeItem => nodeItem.slug === node.slug)[0];

      if(nodeExists){
        return true;
      }else{
        return false;
      }
    }

    searchTerm = searchTerm.toLowerCase()

    for(let contentfulType of Object.keys(content)){
      const excludedTypes = ["allSitePage", "allContentfulMenuItem"]
      if(excludedTypes.includes(contentfulType)){
        continue;
      }
      // console.log("edges", content[contentfulType])
      let edges = content[contentfulType].filter(edge => {
        if(edge.node){
          return edge.node.spaceId === process.env.GATSBY_INVESTOR_SPACE_ID
        }
      });
      for(let edge of edges){
          let {node} = edge; 
          for(let nodeKey in node){
            if(node.hasOwnProperty(nodeKey)){
              let nodeItemValue = node[nodeKey];
              if(isString(nodeItemValue)){
                  if(nodeItemValue.toLowerCase().includes(searchTerm)){
                    let nodeExists = doesNodeExistOnFilteredNodes(filteredNodes, node);
                    if(!nodeExists){
                      filteredNodes.push({...node, matchedSearchText: nodeItemValue});
                    }
                  }
              }else if(isObject(nodeItemValue) && !isArray(nodeItemValue)){
                  for(let richTextObject in nodeItemValue){
                    if(nodeItemValue.hasOwnProperty(richTextObject)){
                        let richText = nodeItemValue[richTextObject];
                        for(let htmlText in richText){
                            if(richText.hasOwnProperty(htmlText)){
                                let html = richText[htmlText];
                                if(html.toLowerCase().includes(searchTerm)){
                                  let nodeExists = doesNodeExistOnFilteredNodes(filteredNodes, node);
                                  if(!nodeExists){
                                    filteredNodes.push({...node, matchedSearchText: html});
                                  }
                                }
                            }
                        }
                    }
                }
              }else if(isArray(nodeItemValue)){
                for(let objectItem of nodeItemValue){
                  if(isObject(objectItem) && !isArray(objectItem)){
                      for(let itemKey in objectItem){
                          if(objectItem.hasOwnProperty(itemKey)){
                              let item = objectItem[itemKey];
                              if(isString(item)){
                                if(item.toLowerCase().includes(searchTerm)){
                                  let nodeExists = doesNodeExistOnFilteredNodes(filteredNodes, node);
                                  if(!nodeExists){
                                    filteredNodes.push({...node, matchedSearchText: item});
                                  }
                                }
                              }else if(isObject(item) && !isArray(item)){
                                for(let richTextObject in item){
                                  let richText = item[richTextObject];
                                  for(let htmlText in richText){
                                    if(richText.hasOwnProperty(htmlText)){
                                      let html = richText[htmlText];
                                      if(html.toLowerCase().includes(searchTerm)){
                                        let nodeExists = doesNodeExistOnFilteredNodes(filteredNodes, node);
                                        if(!nodeExists){
                                          filteredNodes.push({...node, matchedSearchText: html});
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                          }
                      }
                  }
              }
            }
            }
          }
      }
    }

    return filteredNodes;
}

export const graphqlSearch = async (query, searchTerm) => {
    if(isNullUndefined(query)){
        return null;
    }

    let allContent = getContentAsObject(query);

    let filteredContent = getFilteredContentFromSearch(allContent, searchTerm)

    return filteredContent;
}

export const getSearchedPagePath = (slug, query) => {
    if(isNullUndefined(slug)){
      return ""
    }

    let edges = query.allSitePage.edges;

    for(let edge of edges){
      let path = edge.node.path;

      if(path.startsWith("/Investor")){
        let pathArray = path.split("/").filter(Boolean);
        let pathSlug = pathArray[pathArray.length - 1]

        if(pathSlug === slug){
          return path;
        }
      }
    }

    return ""
}